/* You can add global styles to this file, and also import other style files */
@import '../node_modules/angular-calendar/css/angular-calendar.css';
@import '/src/assets/styles/color';

@tailwind base;
@tailwind components;
@tailwind utilities;

$required-indicator-content: " *";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  color: $emphasis-high;
}

.link {
  color: $main-primary-800;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: $status-info-main;
  }
  &.no-underline {
    text-decoration: none;
  }
}

table {
  border-collapse: separate;
}

.divider {
  width: 100%;
  height: 1px;
  border-top: 1px solid $emphasis-low;
  margin: 24px 0;
}
// For IBM Accessibility
// .mat-focus-indicator:focus {
//   outline: 2px solid #124462 !important;
//   border-radius: 2px !important;
// }

// .mat-select:focus{
//   outline: 2px solid #124462 !important;
//   border-radius: 2px !important;
// }
// .mat-input-element:focus {
//   outline: 2px solid #124462 !important;
//   border-radius: 2px !important;
// }
// .cdk-visually-hidden {
//   display: none !important;
// }
// .cdk-overlay-backdrop.cdk-overlay-backdrop-showing{
//   opacity: 0 !important;
//   background: transparent !important;
// }
.cdk-visually-hidden.cdk-focus-trap-anchor {
  display: none;
}
.mat-ripple {
  display: none;
}

.icon-status-container {
  .icon-status {
    display: none !important;
  }
}

.mat-no-data-row {
  td {
    padding: 50px !important;
  }
}

.--required-indicator::after {
  content: $required-indicator-content;
  color: $required-indicator-color;
}
