$darker: #000000;
$lighter: #ffffff;

$main-action-default: #2d6c71;
$main-action-active: mix($darker, $main-action-default, $weight: 10%);
$main-action-hover: mix($lighter, $main-action-default, $weight: 95%);

$main-primary-900: mix($darker, $main-action-default, $weight: 55%);
$main-primary-800: mix($darker, $main-action-default, $weight: 40%);
$main-primary-700: mix($darker, $main-action-default, $weight: 30%);
$main-primary-600: mix($darker, $main-action-default, $weight: 5%);

$main-secondary-default: #daad4a;
$main-secondary-900: #daad4a;
$main-secondary-800: #e1bd6e;
$main-secondary-700: #f8e1ae;
$main-secondary-600: #fcf3df;

$emphasis-high: #303030;
$emphasis-medium: #666666;
$emphasis-low: #cecece;
$emphasis-white: #ffffff;

$others-link: #00c14b;
$others-link-blue: #60a5ce;
$others-outline-grayscale: #ebebeb;
$others-outline-color: #2c75a0;
$others-background-light: #f5f5f5;
$others-background-medium: #e2e2e2;

$status-info-main: #2d6c71;
$status-info-900: #1350aa;
$status-info-800: #6197e7;
$status-info-700: mix($lighter, #dbeaff, $weight: 50%);

$status-success-main: #14c357;
$status-success-900: #0b7e37;
$status-success-800: #5fe392;
$status-success-700: #c3ffda;

$status-warning-main: #fdd855;
$status-warning-900: #7b4200;
$status-warning-800: #fbc94a;
$status-warning-700: #f7f5d1;

$status-danger-main: #fb9d9e;
$status-danger-900: #7e0e0e;
$status-danger-800: #dd5959;
$status-danger-700: mix($lighter, #fde5e2, $weight: 20%);

$box-shadow: #00000033;
$box-shadow-config-screen: rgba(36, 43, 69, 0.08);
$cancel-button-background: #333333;
$white-btn: #fff;
$progress-spinner-circle: #124462;
$disabled-main-primary-600: mix($lighter, $main-primary-600, $weight: 80%);

$field-control-background: #f0f0f0;
$field-control-underline: #789b8c;

$border-color: #ebebeb;
$mask-text-color: #b1b1b1;

$chip-background: #f5f5f5;
$chip-text: #828282;
$chip-hover-focus-background: #408bf91a;
$chip-text-primary: #303030;
$chip-text-remove: #cecece;
$chip-hover-focus-remove: #408bf9;

$form-error: #f44336;
$form-label-focus:#3f51b5;
$form-background: #f5f5f5;
$required-indicator-color: #f44336;

$header-text: #124462;
$header-box-shadow: rgb(0 0 0 / 20%);

$wcag-label-text: #555555;
$wcag-default-icon: #555555;
$wcag-field-label-text: #333333;
